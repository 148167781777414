/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from '@buddieshr/styles/components/buddiesHREffect.module.scss';
import PageWrapper from '../templates/pageWrapper';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from 'antd';
import PricingButtonHint from '../pricing/pricingbuttonHint';
import AnimateOnShow from '../animateOnShow';

const BuddiesHREffect = () => (
  <div className={styles.wrapper}>
    <PageWrapper width={920}>
      <h2 className={styles.title}>
        Experience the BuddiesHR effect{' '}
        <Image
          src="/img/animated-emojis/Exploding%20Head.png"
          alt="Star-Struck"
          width="40"
          height="40"
          style={{
            marginLeft: 10,
          }}
        />
      </h2>
      <div className={styles.content}>
        <AnimateOnShow>
          <div className={styles.elementWrapper}>
            <div className={styles.elementInner}>
              <div className={styles.arrow}>
                <Image
                  src="/img/gifsScreens/arrow-white-2.png"
                  alt="arrow"
                  width={987}
                  height={464}
                />
              </div>
              {/* <div className={styles.appPicture}>
              <Image
                src="/img/apps/alfy/product_screen.png"
                alt="random coffee chat slack"
                width={400}
                height={200}
                className={styles.billy}
              />
            </div> */}
              <div className={styles.elementLeft}>
                <div className={styles.with}>I feel lonely</div>
                <div>
                  <Image
                    src="/img/gifsScreens/kevin.png"
                    alt="alone for birthday"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
              <div className={styles.elementRight}>
                <div className={styles.with}>{'I feel included'}</div>
                <div>
                  <Image
                    src="/img/gifsScreens/friends.png"
                    alt="team bonding"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.bottomLogo}>
                <Image
                  src="/img/apps/alfy/logo.png"
                  width={72}
                  height={72}
                  alt="logo random coffee"
                />
              </div>
              <div className={styles.bottomContent}>
                <div className={styles.bottomHow}>
                  <div className={styles.emoji}>👉</div>
                  <div>
                    {
                      'Alfy connects employees together through Random Coffee Chats.'
                    }
                  </div>
                </div>
                <div className={styles.bottomBenefit}>
                  <div className={styles.emoji}>✅</div>
                  <div>
                    {
                      'It connects people, strenghten team bonding and boosts productivity.'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateOnShow>
        <AnimateOnShow>
          <div className={styles.elementWrapper}>
            <div className={styles.elementInner}>
              <div className={styles.arrow}>
                <Image
                  src="/img/gifsScreens/arrow-white-2.png"
                  alt="arrow"
                  width={987}
                  height={464}
                />
              </div>
              {/* <div className={styles.appPicture}>
              <Image
                src="/img/apps/billy/preview-classic.png"
                alt="workaversary slack"
                width={400}
                height={200}
                className={styles.billy}
              />
            </div> */}
              <div className={styles.elementLeft}>
                <div className={styles.with}>No one notices my bday</div>
                <div>
                  <Image
                    src="/img/gifsScreens/alone-stairs.png"
                    alt="alone for birthday"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
              <div className={styles.elementRight}>
                <div className={styles.with}>We celebrate together</div>
                <div>
                  <Image
                    src="/img/gifsScreens/party.png"
                    alt="celebrating birthday at work"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.bottomLogo}>
                <Image
                  src="/img/apps/billy/logo.png"
                  width={72}
                  height={72}
                  alt="logo birthday app"
                />
              </div>
              <div className={styles.bottomContent}>
                <div className={styles.bottomHow}>
                  <div className={styles.emoji}>👉</div>
                  <div>
                    {
                      ' Billy automates birthday and work anniversary celebrations.'
                    }
                  </div>
                </div>
                <div className={styles.bottomBenefit}>
                  <div className={styles.emoji}>✅</div>
                  <div>
                    {
                      " Never forget a colleague's birthday again and make employees feel appreciated."
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateOnShow>
        <AnimateOnShow>
          <div className={styles.elementWrapper}>
            <div className={styles.elementInner}>
              <div className={styles.arrow}>
                <Image
                  src="/img/gifsScreens/arrow-white-2.png"
                  alt="arrow"
                  width={987}
                  height={464}
                />
              </div>
              {/* <div className={styles.appPicture}>
              <Image
                src="/img/apps/clappy/screen_2.png"
                alt="recognitions and kudos for slack"
                width={400}
                height={200}
                className={styles.clappy}
              />
            </div> */}
              <div className={styles.elementLeft}>
                <div className={styles.with}>I do not feel recognized</div>
                <div>
                  <Image
                    src="/img/gifsScreens/sad-mickael.png"
                    alt="not recognized employees"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
              <div className={styles.elementRight}>
                <div className={styles.with}>{'I feel appreciated'}</div>
                <div>
                  <Image
                    src="/img/gifsScreens/mickael-happy.png"
                    alt="kudos slack"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.bottomLogo}>
                <Image
                  src="/img/apps/clappy/logo.png"
                  width={72}
                  height={72}
                  alt="logo recognition slack"
                />
              </div>
              <div className={styles.bottomContent}>
                <div className={styles.bottomHow}>
                  <div className={styles.emoji}>👉</div>
                  <div>
                    {
                      ' Clappy makes it easy to send Kudos and recognition messages.'
                    }
                  </div>
                </div>
                <div className={styles.bottomBenefit}>
                  <div className={styles.emoji}>✅</div>
                  <div>
                    {
                      ' It facilitates authentic and meaningful appreciation among your employees boosting morale and the sense of belonging.'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateOnShow>
        <AnimateOnShow>
          <div className={styles.elementWrapper}>
            <div className={styles.elementInner}>
              <div className={styles.arrow}>
                <Image
                  src="/img/gifsScreens/arrow-white-2.png"
                  alt="arrow"
                  width={987}
                  height={464}
                />
              </div>
              {/* <div className={styles.appPicture}>
              <Image
                src="/img/apps/pulsy/product/screen_answer_2.png"
                alt="standups, surveys and polls"
                width={400}
                height={200}
                className={styles.billy}
              />
            </div> */}
              <div className={styles.elementLeft}>
                <div className={styles.with}>{"I don't feel listened to"}</div>
                <div>
                  <Image
                    src="/img/gifsScreens/stanley-unhappy.png"
                    alt="un-recognized employees"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
              <div className={styles.elementRight}>
                <div className={styles.with}>I feel important</div>
                <div>
                  <Image
                    src="/img/gifsScreens/stanley-happy.png"
                    alt="kudos slack"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.bottomLogo}>
                <Image
                  src="/img/apps/pulsy/logo.png"
                  width={72}
                  height={72}
                  alt="logo survey app"
                />
              </div>
              <div className={styles.bottomContent}>
                <div className={styles.bottomHow}>
                  <div className={styles.emoji}>👉</div>
                  <div>
                    {
                      ' Pulsy makes it easy to send Polls, Surveys, eNPS or run Standups remotely.'
                    }
                  </div>
                </div>
                <div className={styles.bottomBenefit}>
                  <div className={styles.emoji}>✅</div>
                  <div>
                    {
                      ' It makes sure employees can submit feedback, communicate issues and feel listened to.'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateOnShow>
        <AnimateOnShow>
          <div className={styles.elementWrapper}>
            <div className={styles.elementInner}>
              <div className={styles.arrow}>
                <Image
                  src="/img/gifsScreens/arrow-white-2.png"
                  alt="arrow"
                  width={987}
                  height={464}
                />
              </div>
              {/* <div className={styles.appPicture}>
              <Image
                src="/img/apps/pulsy/product/screen_answer_2.png"
                alt="standups, surveys and polls"
                width={400}
                height={200}
                className={styles.billy}
              />
            </div> */}
              <div className={styles.elementLeft}>
                <div className={styles.with}>
                  {"I don't know who does what"}
                </div>
                <div>
                  <Image
                    src="/img/gifsScreens/no-idea-2.png"
                    alt="un-recognized employees"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
              <div className={styles.elementRight}>
                <div className={styles.with}>I understand my company</div>
                <div>
                  <Image
                    src="/img/gifsScreens/idea.png"
                    alt="kudos slack"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.bottomLogo}>
                <Image
                  src="/img/apps/linky/logo.png"
                  width={72}
                  height={72}
                  alt="logo org chart"
                />
              </div>
              <div className={styles.bottomContent}>
                <div className={styles.bottomHow}>
                  <div className={styles.emoji}>👉</div>
                  <div>
                    {
                      'Linky helps you build and keep up-to-date your organisation chart and employee directory'
                    }
                  </div>
                </div>
                <div className={styles.bottomBenefit}>
                  <div className={styles.emoji}>✅</div>
                  <div>
                    {
                      'It helps people have a clear view on teams and department. Also great for onboarding new employees.'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateOnShow>
        <AnimateOnShow>
          <div className={styles.elementWrapper}>
            <div className={styles.elementInner}>
              <div className={styles.arrow}>
                <Image
                  src="/img/gifsScreens/arrow-white-2.png"
                  alt="arrow"
                  width={987}
                  height={464}
                />
              </div>
              {/* <div className={styles.appPicture}>
              <Image
                src="/img/apps/pulsy/product/screen_answer_2.png"
                alt="standups, surveys and polls"
                width={400}
                height={200}
                className={styles.billy}
              />
            </div> */}
              <div className={styles.elementLeft}>
                <div className={styles.with}>
                  {'Wasting 1h in a meeting every morning'}
                </div>
                <div>
                  <Image
                    src="/img/gifsScreens/stany1.png"
                    alt="standup meetings done wrong"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
              <div className={styles.elementRight}>
                <div className={styles.with}>I can focus on my work.</div>
                <div>
                  <Image
                    src="/img/gifsScreens/stany2.png"
                    alt="kudos slack"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.bottomLogo}>
                <Image
                  src="/img/apps/stany/logo.png"
                  width={72}
                  height={72}
                  alt="stany standup slack"
                />
              </div>
              <div className={styles.bottomContent}>
                <div className={styles.bottomHow}>
                  <div className={styles.emoji}>👉</div>
                  <div>{'Stany helps you run async standups in Slack.'}</div>
                </div>
                <div className={styles.bottomBenefit}>
                  <div className={styles.emoji}>✅</div>
                  <div>
                    {
                      'It saves hours of daily meetings and makes it easy for others to read the updates and helps on the potential blockers.'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateOnShow>
        <AnimateOnShow>
          <div className={styles.elementWrapper}>
            <div className={styles.elementInner}>
              <div className={styles.arrow}>
                <Image
                  src="/img/gifsScreens/arrow-white-2.png"
                  alt="arrow"
                  width={987}
                  height={464}
                />
              </div>
              <div className={styles.elementLeft}>
                <div className={styles.with}>
                  {"It'll take hours to log my hours, oh wait 🤔"}
                </div>
                <div>
                  <Image
                    src="/img/gifsScreens/pam1.png"
                    alt="log time in slack"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
              <div className={styles.elementRight}>
                <div className={styles.with}>
                  I'm always up-to-date with my hours declared
                </div>
                <div>
                  <Image
                    src="/img/gifsScreens/pam2.png"
                    alt="time tracking app"
                    width={550}
                    height={330}
                  />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.bottomLogo}>
                <Image
                  src="/img/apps/ticky/logo.png"
                  width={72}
                  height={72}
                  alt="ticky time tracking slack"
                />
              </div>
              <div className={styles.bottomContent}>
                <div className={styles.bottomHow}>
                  <div className={styles.emoji}>👉</div>
                  <div>
                    {
                      'Ticky helps employees log their time spent on projects/clients directly in Slack.'
                    }
                  </div>
                </div>
                <div className={styles.bottomBenefit}>
                  <div className={styles.emoji}>✅</div>
                  <div>
                    {
                      "HRs and Managers don't have to chase employees about declaring hours anymore, they can focus on more important tasks. "
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateOnShow>
      </div>
      <AnimateOnShow>
        <div className={styles.finalCTAWrapper}>
          <h3>
            All in all, everything you need to engage your team <br />
            for only $2 per employee per month
          </h3>
          <div className={styles.finalCTA}>
            <Link href="/get-started">
              <Button
                style={{
                  color: '#fff',
                  backgroundColor: '#101957',
                  minWidth: 220,
                }}
                type="primary"
                size="large"
              >
                Start for free
              </Button>
            </Link>
            <Link href="/pricing">
              <Button
                type="link"
                size="large"
                style={{ minWidth: 220, color: '#69b1ff' }}
              >
                View pricing
              </Button>
            </Link>
          </div>
          <div style={{ width: '400px', maxWidth: '100%', margin: 'auto' }}>
            <PricingButtonHint style={{ color: '#C0C0C0' }} />
          </div>
        </div>
      </AnimateOnShow>
    </PageWrapper>
  </div>
);

export default BuddiesHREffect;
