import React from 'react';
import styles from '@buddieshr/styles/components/whycare.module.scss';
import presets from '@buddieshr/styles/presets.module.scss';
import TextUnderlined from '../text/textUnderlined';
import { FallOutlined, RiseOutlined } from '@ant-design/icons';
import Link from 'next/link';

const WhyCare = ({ short = false }) => (
  <div className={styles.wrapperSection}>
    {!short && (
      <div className={styles.titleFlex}>
        <div className={styles.titleFlex1}>
          <h2>Why should I care about employee engagement?</h2>
        </div>
        <div className={styles.titleFlex2}>
          Attracting, recruiting, and retaining good employees isn’t easy. With
          a distributed or remote workforce, it’s even more challenging.
          <br />
          <br />
          Based on decades of employee engagement research,{' '}
          <TextUnderlined>Gallup</TextUnderlined> knows that engaged employees
          produce better business outcomes than other employees.
        </div>
      </div>
    )}

    <p style={{ marginBottom: 12 }}>
      BuddiesHR increases employee engagement. <br />
      According to{' '}
      <Link
        target="_blank"
        href="https://www.gallup.com/workplace/236927/employee-engagement-drives-growth.aspx"
        style={{ textDecoration: 'underline' }}
      >
        Gallup study
      </Link>
      , engaged employees produce better business outcomes:
    </p>
    <div className={styles.wrapper}>
      <div className={styles.element}>
        <div className={styles.icon}>
          <FallOutlined />
        </div>
        <div className={styles.number}>-81%</div>
        <div className={styles.tag}>
          Less absenteism
          {/* (
          <a
            href="https://www.gallup.com/workplace/285674/improve-employee-engagement-workplace.aspx"
            style={{ color: '#0015ef', textDecoration: 'underline' }}
          >
            2
          </a>
          ) */}
        </div>
        <div className={styles.barre}></div>
      </div>
      <div className={styles.element}>
        <div className={styles.icon}>
          <RiseOutlined />
        </div>
        <div className={styles.number}>+18%</div>
        <div className={styles.tag}>
          More Productivity
          {/* (
          <a
            href="https://www.gallup.com/workplace/285674/improve-employee-engagement-workplace.aspx"
            style={{ color: '#0015ef', textDecoration: 'underline' }}
          >
            3
          </a>
          ) */}
        </div>
      </div>
      <div className={styles.element}>
        <div className={styles.icon}>
          <RiseOutlined />
        </div>
        <div className={styles.number}>+10%</div>
        <div className={styles.tag}>
          Customer loyalty
          {/* (
          <a
            href="https://www.gallup.com/workplace/285674/improve-employee-engagement-workplace.aspx"
            style={{ color: '#0015ef', textDecoration: 'underline' }}
          >
            3
          </a>
          ) */}
        </div>
      </div>
      <div className={styles.element}>
        <div className={styles.icon}>
          <RiseOutlined />
        </div>
        <div className={styles.number}>+23%</div>
        <div className={styles.tag}>
          Profitability
          {/* (
          <a
            href="https://www.gallup.com/workplace/285674/improve-employee-engagement-workplace.aspx"
            style={{ color: '#0015ef', textDecoration: 'underline' }}
          >
            3
          </a>
          ) */}
        </div>
      </div>
    </div>

    {/* <div>It pays to invest in your people</div> */}
  </div>
);

export default WhyCare;
